'use strict';

var WINDOW_WIDTH = 550;
var WINDOW_HEIGHT = 520;
var HALF_DIVISOR = 2;

function createSharerWindow() {
  var links = document.querySelectorAll('.js-sharer-window');

  for (var i = 0; i < links.length; i++) {
    var link = links[i];

    link.addEventListener('click', function(event) {
      event.preventDefault();
      var trigger = findCorrectClickTarget(event.target, 'js-sharer-window');

      newCenteredWindow(trigger.href, trigger.title, window);
    });
  }
}

function newCenteredWindow(url, title, win, width, height) {
  if (width === undefined) {
    var width = WINDOW_WIDTH;
  }

  if (height === undefined) {
    var height = WINDOW_HEIGHT;
  }

  var y = win.top.outerHeight / HALF_DIVISOR + win.top.screenY - ( height / HALF_DIVISOR);
  var x = win.top.outerWidth / HALF_DIVISOR + win.top.screenX - ( width / HALF_DIVISOR);

  return window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + width + ', height=' + height + ', top=' + y + ', left=' + x);
}

var findCorrectClickTarget = function (el, cls) {
  while (!el.classList.contains(cls)) {
    el = el.parentElement;
  }

  return el;
}

module.exports = createSharerWindow;
