'use strict';

var togglePanel = function () {
  var triggers = document.querySelectorAll('.js-toggle-panel');

  for (var i = triggers.length - 1; i >= 0; i--) {
    triggers[i].addEventListener('click', clickEvent, false);
  }
};

var clickEvent = function (event) {
  console.log('click!')
  event.preventDefault();
  event.stopPropagation();

  var trigger = findCorrectClickTarget(event.target, 'js-toggle-panel');
  var targetPanel = document.querySelector(trigger.dataset.targetPanel);
  var optionalParentTarget = document.querySelector(trigger.dataset.parentTarget) || null;
  var parentClass = trigger.dataset.parentClass || null;

  if (optionalParentTarget == null || parentClass == null) {
    optionalParentTarget = null;
    parentClass = null;
  }

  if (trigger.classList.contains('is-active')) {
    hidePanel(trigger, targetPanel, optionalParentTarget, parentClass);
  } else {
    showPanel(trigger, targetPanel, optionalParentTarget, parentClass);
  }
};

var showPanel = function (trigger, targetPanel, optionalParentTarget, parentClass) {
  trigger.classList.add('is-active');
  targetPanel.classList.add('is-visible');

  trigger.setAttribute('aria-expanded', true);
  targetPanel.setAttribute('aria-hidden', false);

  if (optionalParentTarget != null && parentClass != null) {
    optionalParentTarget.classList.add(parentClass);
  }
};

var hidePanel = function (trigger, targetPanel, optionalParentTarget, parentClass) {
  trigger.classList.remove('is-active');
  targetPanel.classList.remove('is-visible');

  trigger.setAttribute('aria-expanded', false);
  targetPanel.setAttribute('aria-hidden', true);

  if (optionalParentTarget != null && parentClass != null) {
    optionalParentTarget.classList.remove(parentClass);
  }
};

var findCorrectClickTarget = function (el, cls) {
  while (!el.classList.contains(cls)) {
    el = el.parentElement;
  }

  return el;
}

module.exports = togglePanel;
