'use strict';

// import Cookies from 'js-cookie'
window.Cookies = require('js-cookie');

const containerSelector = '[data-cookie-consent-role="container"]'
const dismissButtonSelector = '[data-cookie-consent-role="dismiss"]'
const eventName = 'data_collection_notice'
const cookieName = 'cookies-accepted'

const container = document.querySelector(containerSelector)
const dismissButton = document.querySelector(dismissButtonSelector)

const dismissToolbar = function () {
  window.Cookies.set(cookieName, '1', { expires: 365 })
  container.setAttribute('hidden', 'hidden')
}

const cookieConsent = function () {

  const targetCookie = window.Cookies.get(cookieName)
  const isDismissed = targetCookie > 0

  // if not already dismissed then show toolbar
  if (!isDismissed) {
    container.removeAttribute('hidden')
  }

  // fire datalayer event to say cookie has been seen
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: eventName,
  })

  // attach event listener to dismiss
  dismissButton.addEventListener('click', dismissToolbar, false);
};

module.exports = cookieConsent;
