'use strict';

var backButtonEl = document.querySelector('.js-back');

var backButton = function () {
  var needle;

  if (backButtonEl === null) {
    return;
  }

  needle = backButtonEl.dataset.referrerNeedle;

  if (needle != null && document.referrer.indexOf(needle) > -1 && document.referrer != document.URL) {
    backButtonEl.href = document.referrer;
  }
};

module.exports = backButton;
