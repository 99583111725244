/*
  Project: nmnt
  Author: tvou
 */

var accordions = require('./accordions.js');
var bannerImage = require('./banner-image.js');
var cookieConsent = require('./cookie-consent.js');
var togglePanel = require('./toggle-panel.js');
var stickyHeader = require('./sticky-header.js');
var sharerWindow = require('./sharer-window.js');
var backButton = require('./back-button.js');
var responsiveTables = require('./responsive-tables.js');

accordions();
bannerImage();
cookieConsent();
togglePanel();
stickyHeader();
sharerWindow();
backButton();
responsiveTables();
