'use strict';

var header = document.querySelector('header');
var topStrip = document.querySelector('.c-header__top-strip');
var topStripHeight = 0;
var latestKnownScrollY = 0;
var firstStagePoint = 0;
var secondStagePoint = 0;
var thirdStagePoint = 0;
var ticking = false;

var update = function() {
  ticking = false;
  var currentScrollY = latestKnownScrollY;

  if (currentScrollY > firstStagePoint) {
    header.classList.add('c-header--ready');
  } else {
    header.classList.remove('c-header--ready');
  }

  if (currentScrollY > secondStagePoint) {
    header.classList.add('c-header--fixed');
  } else {
    header.classList.remove('c-header--fixed');
  }

  if (currentScrollY > thirdStagePoint) {
    header.classList.add('c-header--pinned');
  } else {
    header.classList.remove('c-header--pinned');
  }
};

var requestTick = function() {
  if (!ticking) {
    requestAnimationFrame(update);
  }

  ticking = true;
};

var onScroll = function() {
  latestKnownScrollY = window.pageYOffset;
  requestTick();
};

var resetStickyHeaderValues = function() {
  var headerHeight = header.clientHeight;

  if (topStrip != null) {
    topStripHeight = topStrip.clientHeight;
  }

  firstStagePoint = headerHeight + topStripHeight + 8;
  secondStagePoint = firstStagePoint + 32; // 32px = breathing room for transitions
  thirdStagePoint = secondStagePoint + 32;
};

var stickyHeader = function() {
  resetStickyHeaderValues();
  window.addEventListener('resize', resetStickyHeaderValues, false);
  window.addEventListener('scroll', onScroll, false);
};

module.exports = stickyHeader;
