'use strict';

var accordions = function () {
  var accordionTriggers = document.querySelectorAll('.js-accordion-trigger');

  for (var i = accordionTriggers.length - 1; i >= 0; i--) {
    accordionTriggers[i].addEventListener('click', clickEvent, false);
  }
};

var clickEvent = function (e) {
  event.preventDefault();

  var trigger = findCorrectClickTarget(e.target, 'js-accordion-trigger');
  var targetPanel = document.querySelector(trigger.dataset.targetPanel);

  if (trigger.classList.contains('is-expanded')) {
    hidePanel(trigger, targetPanel);
  } else {
    showPanel(trigger, targetPanel);
  }
};

var hidePanel = function (trigger, targetPanel) {
  trigger.classList.remove('is-expanded');
  trigger.setAttribute('aria-expanded', false);

  targetPanel.setAttribute('aria-hidden', true);
  targetPanel.classList.add('is-hidden');
  targetPanel.setAttribute('tabindex', -1);
};

var showPanel = function (trigger, targetPanel) {
  trigger.classList.add('is-expanded');
  trigger.setAttribute('aria-expanded', true);

  targetPanel.setAttribute('aria-hidden', false);
  targetPanel.classList.remove('is-hidden');
  targetPanel.setAttribute('tabindex', 0);
};

var findCorrectClickTarget = function (el, cls) {
  while (!el.classList.contains(cls)) {
    el = el.parentElement;
  }

  return el;
}

module.exports = accordions;
